var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-banner',[_c('div',{staticClass:"d-flex justify-space-between pr-4"},[_c('p',{staticClass:"text-h5 font-weight-medium mb-0"},[_vm._v(" "+_vm._s(_vm.group.group)+" ")]),_c('router-link',{attrs:{"to":"/private/accounting/groups"}},[_c('v-btn',{attrs:{"color":"secondary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Regresar ")],1)],1)],1)]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.data.headers,"items":_vm.data.data,"item-per-page":5,"search":_vm.search,"loading":_vm.data.load,"loading-text":"Cargando datos...","sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Lista de Donadores ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.phone)+" ")])]}},{key:"item.frequent_donation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.frequent_donation)+" ")])]}},{key:"item.last_transaction_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.last_transaction_date)+" ")])]}},{key:"item.promotion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.promotion)+" ")])]}},{key:"item.person_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.person_type)+" ")])]}},{key:"item.voucher",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.voucher),callback:function ($$v) {_vm.$set(item, "voucher", $$v)},expression:"item.voucher"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.modalDetail(item)}}},[_vm._v(" mdi-eye ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDonorsGroup}},[_vm._v(" Recargar ")])]},proxy:true}],null,true)})],1)],1)],1)],1),_c('DetailDonation',{ref:"mdlDetail",attrs:{"donor":_vm.slcDonor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card>
      <v-card-text class="p-0">
        <v-container class="text--primary p-0">
          <v-data-table
            :headers="data.headers"
            :items="data.data"
            :item-per-page="5"
            :loading="data.load"
            loading-text="Cargando datos..."
            sort-by="transaction_time_format"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="text-h5">
                  Lista de Donaciones
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.transaction_amount`]="{ item }">
              <div class="text-nowrap">
                $ {{ parseFloat(item.transaction_amount).toFixed(2) }}
              </div>
            </template>
            <!-- Si no carga la data -->
            <template v-slot:no-data>
              <v-btn color="primary" @click="getDonorsGroup">
                Recargar
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'DetailDonation',
  data() {
    return {
      dialog: false,
      data: {
        load: false,
        data: [],
        headers: [
          { text: 'Fecha', value: 'transaction_time_format' },
          { text: 'Cantidad', value: 'transaction_amount' },
          { text: '# Transacción', value: 'recibo_id' },
          { text: '# Autorización', value: 'transaction_authorization' },
        ],
      },
    };
  },
  props: { donor: Object },
  watch: {
    donor() {
      this.getTransactions();
    },
  },
  methods: {
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    getTransactions: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'tx/donor/' + this.donor.id_donor)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener los datos',
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),
  },
};
</script>

<style></style>

<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0">
          {{ group.group }}
        </p>
        <router-link to="/private/accounting/groups">
          <v-btn color="secondary">
            <v-icon left>mdi-arrow-left</v-icon>
            Regresar
          </v-btn>
        </router-link>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
              sort-by="name"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de Donadores
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div class="text-nowrap">
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <div class="text-nowrap">
                  {{ item.email }}
                </div>
              </template>
              <template v-slot:[`item.phone`]="{ item }">
                <div class="text-nowrap">
                  {{ item.phone }}
                </div>
              </template>
              <template v-slot:[`item.frequent_donation`]="{ item }">
                <div class="text-nowrap">
                  {{ item.frequent_donation }}
                </div>
              </template>
              <template v-slot:[`item.last_transaction_date`]="{ item }">
                <div class="text-nowrap">
                  {{ item.last_transaction_date }}
                </div>
              </template>
              <template v-slot:[`item.promotion`]="{ item }">
                <div class="text-nowrap">
                  {{ item.promotion }}
                </div>
              </template>
              <template v-slot:[`item.person_type`]="{ item }">
                <div class="text-nowrap">
                  {{ item.person_type }}
                </div>
              </template>
              <template v-slot:[`item.voucher`]="{ item }">
                <v-simple-checkbox
                  v-model="item.voucher"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="modalDetail(item)">
                  mdi-eye
                </v-icon>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getDonorsGroup">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modal -->
    <DetailDonation ref="mdlDetail" :donor="slcDonor" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DetailDonation from "./DetailDonation.vue";
export default {
  name: "GroupAccounting",
  components: {
    DetailDonation,
  },
  data: () => ({
    group: { group: "" },
    data: {
      load: true,
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Correo Electrónico", value: "email" },
        { text: "Télefono", value: "phone" },
        { text: "Frecuencia", value: "frequent_donation" },
        { text: "Última Donación", value: "last_transaction_date" },
        { text: "Promoción ITR", value: "promotion" },
        { text: "Tipo", value: "person_type" },
        { text: "Comprobante", value: "voucher" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      data: [],
    },
    slcDonor: {},
    search: "",
  }),
  mounted() {
    this.getGroup();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getDonorsGroup: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "donor_group/all/donors_group/" + this.group.id_group)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
    getGroup: async function() {
      await this.$http
        .get(this.api + "group/" + this.$route.params.id_group)
        .then(({ data }) => {
          this.group = data;
          this.getDonorsGroup();
        });
    },
    modalDetail(item) {
      this.slcDonor = item;
      this.$refs.mdlDetail.changeModal();
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      actions: "getCurrentActions",
    }),
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
